<template>
    <!-- form -->
    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <b-col>
                    <h4 class="text-primary"> <feather-icon size="20" icon="PlusCircleIcon"  /> Tambah Kategori</h4>
                </b-col> 
            </b-row>
            <hr style="margin-top: -1px;">
            <b-row>
                <b-col md="12">
                    <b-form-group   label="Kategori Pelopor Keselamatan">
                        <validation-provider #default="{ errors }" name="kategori Pelpor keselamatan" rules="required">
                            <b-form-input v-model="name" :state="errors.length > 0 ? false:null"
                                placeholder="Kategori Pelopor Keselamatan" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>  
            </b-row>
            <hr>
            <b-row>
                <b-col cols="12">
                    <b-button variant="primary" type="submit"  @click.prevent="validationForm" size="sm"> 
                        <feather-icon size="15" icon="PlusCircleIcon"  class="mr-1" /> 
                        Simpan
                    </b-button>
                    <b-button variant="warning"  @click.prevent="refresh()" size="sm" class="ml-1"> 
                        <feather-icon size="15" icon="RefreshCcwIcon"  class="mr-1" /> 
                        Simpan
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BCardText,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
        },
        data() {
            return {
                emailValue: '',
                name: '',
                required,
                email,
            }
        },
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        // eslint-disable-next-line
                        alert('form submitted!')
                    }
                })
            },
            refresh(){

            }
        },
    }
</script>