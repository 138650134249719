<template>
    <b-row>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">
             
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(avatar)="data">
                    <b-avatar :src="data.value" />
                </template>

                <template #cell(aksi)="data">
                     {{ data.item.id }}
                     <b-badge variant="primary"  class="ml-1" size="sm">  <feather-icon size="15" icon="CheckIcon"  align="right"/>Aktif</b-badge>
                     <b-badge variant="info"  class="ml-1" size="sm">  <feather-icon size="15" icon="CheckIcon"  align="right"/> Non Aktif</b-badge>
                     <b-badge variant="warning"  class="ml-1" size="sm">  <feather-icon size="15" icon="EditIcon" /> Edit</b-badge>
                     <b-badge variant="danger" class="ml-1" size="sm">  <feather-icon size="15" icon="TrashIcon" /> Delete</b-badge>
                </template>
            </b-table>
        </b-col>

        <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [ 
                    {
                        key: 'kategori_pengaduan',
                        label: 'Kategori Pengaduan',
                    }, 
                    {
                        key: 'Aksi',
                        label: '',
                    },  
                ],
                items: [{
                        id: 1, 
                        kategori_pengaduan: "Korrie O'Crevy",  
                    },
                    
                ],
                
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {       
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>